<h2 mat-dialog-title
  >Upload Video for {{ data.game.date | date : 'yyyy-MM-dd' }}
  {{ data.game.homeTeam }} - {{ data.game.awayTeam }}</h2
>
<mat-dialog-content>
  <div class="controls" [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Camera</mat-label>
      <mat-select formControlName="cameraAngle">
        <mat-option *ngFor="let camera of cameras" [value]="camera">{{
          camera
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="margin-left: 16px">
      <mat-label>Variant</mat-label>
      <mat-select formControlName="variant">
        <mat-option *ngFor="let variant of variants" [value]="variant">{{
          variant
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="drag-and-drop"></div>
  <div class="status-bar"></div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    class="close-button"
    (click)="cancel()"
    style="margin-left: 16px; width: 100px"
    >Cancel</button
  >
</mat-dialog-actions>
