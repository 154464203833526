import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadResult } from '@uppy/core';
import {
  CameraAngle,
  VideoFormat,
  VideoVariantEnum,
  VideoVariantType
} from '../domain/game';
import { AlertService } from '../services/alert.service';
import {
  Configuration,
  UploadVideoService
} from '../services/upload-video.service';

export interface DialogUploadResult {
  uploadId: string;
  url: string;
  cameraAngle: CameraAngle;
  variant: VideoVariantType;
  format: VideoFormat;
  size: number;
}

@Component({
  selector: 'app-video-upload-dialog',
  templateUrl: './video-upload-dialog.component.html',
  styleUrls: ['./video-upload-dialog.component.css']
})
export class VideoUploadDialogComponent implements OnInit {
  private configuration: Configuration;
  readonly cameras = Object.values(CameraAngle);
  readonly variants = Object.values(VideoVariantEnum);

  formGroup: FormGroup<{
    cameraAngle: FormControl<CameraAngle | null>;
    variant: FormControl<VideoVariantEnum | null>;
  }>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VideoUploadDialogComponent>,
    private uploadVideoService: UploadVideoService,
    private alertService: AlertService
  ) {
    this.formGroup = new FormGroup({
      cameraAngle: new FormControl(undefined),
      variant: new FormControl(undefined)
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.uploadVideoService.close();
    });
  }

  async ngOnInit() {
    this.configuration = await this.uploadVideoService.getConfiguration();
    this.formGroup.patchValue({
      cameraAngle: this.data.cameraAngle ?? CameraAngle.MAIN,
      variant: this.data.variant
    });
    this.formGroup.valueChanges.subscribe((value) => {
      const { cameraAngle, variant } = value;
      this.uploadVideoService.cameraAngle = cameraAngle;
      this.uploadVideoService.variant = variant;
    });

    this.uploadVideoService.init(this.data.game, this.data.league);
    this.uploadVideoService.on('cancel-all', () => {
      this.alertService.showInfo(`Upload cancelled`);
    });
    this.uploadVideoService.on('complete', (result: UploadResult) => {
      const resultData = result?.successful[0];
      console.log('upload successful', result);
      this.alertService.showInfo(`Upload successful`);

      this.close({
        uploadId: resultData.id,
        url: `${this.configuration.videoUrl}/${resultData?.meta?.name}`,
        cameraAngle: this.formGroup.value.cameraAngle,
        variant: this.formGroup.value.variant,
        format: resultData.type.split('/')[1] as VideoFormat,
        size: resultData.size
      });
    });
  }

  cancel() {
    this.uploadVideoService.cancelAll();
    this.dialogRef.close(null);
  }

  close(result: DialogUploadResult) {
    this.dialogRef.close(result);
  }
}
