<div id="main" class="container-fluid" style="min-height: 500px">
  <div class="row" [class.edit-mode]="isEditMode">
    <div class="col-xs-12 col-sm-8">
      <app-game-navigation [game]="game">
        <app-game-action-menu #actionMenu [game]="game"></app-game-action-menu>
      </app-game-navigation>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Event Type</label>
        <div class="col-sm-10">
          <label
            *ngFor="let eventType of visibleEventTypeOptions"
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            [for]="eventType.value"
          >
            <input
              type="radio"
              [id]="eventType.value"
              class="mdl-radio__button"
              name="et-options"
              [(ngModel)]="event.eventType"
              [value]="eventType.value"
              (change)="onEventTypeChange(eventType.value)"
            />
            <span class="mdl-radio__label">{{ eventType.label }}</span>
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Period</label>
        <div class="col-sm-4">
          <app-period
            ngDefaultControl
            [(ngModel)]="event.period"
            (ngModelChange)="onPeriodChange($event)"
          ></app-period>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Video Time</label>
        <div class="col-sm-2">
          <input
            style="width: 100px; display: inline-block"
            *ngIf="(game.videos ?? []).length > 0"
            disabled
            name="video_time"
            class="form-control"
            type="text"
            value="{{ event.videoTime | hhmmss }}"
          />
          <input
            *ngIf="(game.videos ?? []).length === 0"
            name="video_time"
            class="form-control"
            type="number"
            [(ngModel)]="event.videoTime"
          />
          <button
            mat-button
            style="display: inline-block"
            color="primary"
            (click)="event.videoTime = event.videoTime - 0.1"
            >-0.1s</button
          >
          <button
            mat-button
            style="display: inline-block"
            color="primary"
            (click)="event.videoTime = event.videoTime + 0.1"
            >+0.1s</button
          >
        </div>

        <label class="col-sm-2 col-form-label">Game Time (elapsed sec.)</label>
        <div class="col-sm-2">
          <input
            name="game-time"
            class="form-control"
            type="number"
            style="width: 80px; display: inline; margin-right: 8px"
            [(ngModel)]="event.gameTime"
          />
          <span>{{ event.gameTime | countdown : event.period }}</span>
        </div>

        <label class="col-sm-2 col-form-label" *ngIf="event.realTime"
          >Real Time</label
        >
        <div class="col-sm-2" *ngIf="event.realTime">
          <span>{{ event.realTime | date : 'yyyy-MM-dd hh:mm:ss' }}</span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Strength State</label>
        <div class="col-sm-4">
          <select [(ngModel)]="event.strengthState" class="form-control">
            <option [value]="null"></option>
            <option *ngFor="let s of strengthStates">{{ s }}</option>
          </select>
          <small
            id="strengthStateHelpBlock1"
            class="form-text text-muted"
            *ngIf="event.penaltyDuration === '10'"
          >
            10 min disciplinary penalty do not affect the strength state
          </small>
          <small
            id="strengthStateHelpBlock2"
            class="form-text text-muted"
            *ngIf="event.penaltyDuration === '20'"
          >
            20 min game misconduct penalty do not affect the strength state
          </small>
        </div>
      </div>

      <div *ngIf="isGameSet(); then knownGame"></div>
      <ng-template #knownGame>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Team</label>
          <div class="col-sm-4">
            <select
              [(ngModel)]="event.team"
              (ngModelChange)="onTeamChange($event)"
              [disabled]="isTeamDisabled()"
              class="form-control"
            >
              <option *ngFor="let team of teams">{{ team }}</option>
            </select>
          </div>

          <ng-container
            *ngIf="
              event.eventType &&
              (event.eventType !== 'interruption' ||
                event.eventType !== 'puckPossession')
            "
          >
            <label class="col-sm-2 col-form-label">Player</label>
            <div class="col-sm-4">
              <select
                [(ngModel)]="event.playerNumber"
                class="form-control"
                [disabled]="isPlayerDisabled()"
              >
                <option *ngFor="let p of players">{{ p }}</option>
              </select>
            </div>
          </ng-container>
        </div>
      </ng-template>

      <div class="form-group row" *ngIf="event.eventType === 'time_on_ice'">
        <label class="col-sm-2 col-form-label">Time on Ice type</label>
        <div class="col-sm-10">
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="toit-1"
          >
            <input
              type="radio"
              id="toit-1"
              class="mdl-radio__button"
              name="toit-options"
              [(ngModel)]="event.timeOnIceType"
              value="on"
            />
            <span class="mdl-radio__label">On</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="toit-2"
          >
            <input
              type="radio"
              id="toit-2"
              class="mdl-radio__button"
              name="toit-options"
              [(ngModel)]="event.timeOnIceType"
              value="off"
            />
            <span class="mdl-radio__label">Off</span>
          </label>
        </div>

        <label class="col-sm-2 col-form-label">Empty Net</label>
        <div class="col-sm-4">
          <label
            for="checkbox_home_empty_net_toi"
            class="mdl-checkbox mdl-js-checkbox"
          >
            <input
              id="checkbox_home_empty_net_toi"
              name="checkbox_home_empty_net"
              type="checkbox"
              class="mdl-checkbox__input"
              [checked]="event.isHomeTeamEmptyNet"
              (change)="event.isHomeTeamEmptyNet = !event.isHomeTeamEmptyNet"
            />
            <span class="mdl-checkbox__label"
              >{{ game.homeTeam }} Empty Net</span
            >
          </label>
          <label
            for="checkbox_away_empty_net_toi"
            class="mdl-checkbox mdl-js-checkbox"
          >
            <input
              id="checkbox_away_empty_net_toi"
              name="checkbox_away_empty_net"
              class="mdl-checkbox__input"
              type="checkbox"
              [checked]="event.isAwayTeamEmptyNet"
              (change)="event.isAwayTeamEmptyNet = !event.isAwayTeamEmptyNet"
            />
            <span class="mdl-checkbox__label"
              >{{ game.awayTeam }} Empty Net</span
            >
          </label>
        </div>
      </div>

      <div class="form-group row" *ngIf="event.eventType === 'face_off'">
        <label class="col-sm-2 col-form-label">Team Face-Off Outcome</label>
        <div class="col-sm-4">
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="tfo-1"
          >
            <input
              type="radio"
              id="tfo-1"
              class="mdl-radio__button"
              name="tfo-options"
              [(ngModel)]="event.teamFaceOffOutcome"
              value="win"
            />
            <span class="mdl-radio__label">Won</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="tfo-2"
          >
            <input
              type="radio"
              id="tfo-2"
              class="mdl-radio__button"
              name="tfo-options"
              [(ngModel)]="event.teamFaceOffOutcome"
              value="lost"
            />
            <span class="mdl-radio__label">Lost</span>
          </label>
        </div>

        <label class="col-sm-2 col-form-label">Face off opponent</label>
        <div class="col-sm-4">
          <select [(ngModel)]="event.faceoff_opponent" class="form-control">
            <option value="" selected></option>
            <option
              *ngFor="let p of opponentPlayers"
              [selected]="event.faceoff_opponent === p"
              >{{ p }}</option
            >
          </select>
        </div>
      </div>

      <app-game-event-shot
        *ngIf="event.eventType === 'shot'"
        [game]="game"
        [(event)]="event"
        [hasGameGoalClips]="game.goalClips"
        [players]="players"
        [opponentPlayers]="opponentPlayers"
        (markSlowMotionGoalClipClicked)="
          prepareVideoPlayerTrimDialog($event, VideoClipType.GOAL_SLOW_CLIP)
        "
        (exportGoalClipClicked)="exportGoalClip($event)"
      ></app-game-event-shot>

      <ng-container *ngIf="event.eventType === 'penalty'">
        <div class="form-group row">
          <label class="col-sm-2">Penalty Type</label>
          <div class="col-sm-4">
            <label
              *ngFor="let penaltyTypeValue of ['start', 'expiration']"
              [for]="penaltyTypeValue"
              class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
              >{{ penaltyTypeValue | titlecase }}
              <input
                [id]="penaltyTypeValue"
                type="radio"
                class="mdl-radio__button"
                name="penaltyType"
                [value]="penaltyTypeValue"
                [(ngModel)]="event.penaltyType"
              />
            </label>
          </div>
        </div>

        <ng-container *ngIf="event.penaltyType === 'start'">
          <div class="row">
            <label class="col-sm-2 col-form-label">Penalty Duration</label>
            <div class="col-sm-4">
              <select [(ngModel)]="event.penaltyDuration" class="form-control">
                <option *ngFor="let d of penaltyDurations" [value]="d"
                  >{{ d }}
                </option>
              </select>
            </div>

            <label class="col-sm-2 col-form-label">Fouled Player</label>
            <div class="col-sm-4">
              <select [(ngModel)]="event.fouled_player" class="form-control">
                <option>None</option>
                <option *ngFor="let p of opponentPlayers" [value]="p"
                  >{{ p }}
                </option>
              </select>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="form-group row" *ngIf="event.eventType === 'interruption'">
        <label class="col-sm-2 col-form-label">Interruption reason</label>
        <div class="col-sm-10">
          <label
            *ngFor="let interruptionReason of interruptionReasons; index as idx"
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            [for]="'int-' + idx"
          >
            <input
              type="radio"
              [id]="'int-' + idx"
              class="mdl-radio__button"
              name="int-options"
              [(ngModel)]="event.interruption_type"
              [value]="interruptionReason"
            />
            <span class="mdl-radio__label">{{
              getInterruptionReasonLabel(interruptionReason)
            }}</span>
          </label>
        </div>
      </div>

      <div class="form-group row" *ngIf="event.eventType === 'videoTag'">
        <label class="col-sm-2 col-form-label">Video Tag</label>
        <div class="col-sm-10">
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="vt-1"
          >
            <input
              type="radio"
              id="vt-1"
              class="mdl-radio__button"
              name="vt-options"
              [(ngModel)]="event.videoTag"
              value="low_break_out_under_pressure"
            />
            <span class="mdl-radio__label">Low break-out under pressure</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="vt-2"
          >
            <input
              type="radio"
              id="vt-2"
              class="mdl-radio__button"
              name="vt-options"
              [(ngModel)]="event.videoTag"
              value="board_sector_break_out_under_pressure"
            />
            <span class="mdl-radio__label"
              >Defensive zone board sector break-out under pressure</span
            >
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="vt-3"
          >
            <input
              type="radio"
              id="vt-3"
              class="mdl-radio__button"
              name="vt-options"
              [(ngModel)]="event.videoTag"
              value="d_offensive_blue_line_puck_management"
            />
            <span class="mdl-radio__label"
              >Defenseman offensive blue line puck management</span
            >
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="vt-4"
          >
            <input
              type="radio"
              id="vt-4"
              class="mdl-radio__button"
              name="vt-options"
              [(ngModel)]="event.videoTag"
              value="controlled_offensive_zone_entry"
            />
            <span class="mdl-radio__label"
              >Controlled offensive zone entry</span
            >
          </label>
        </div>
      </div>

      <div class="form-group row" *ngIf="event.eventType === 'pass'">
        <label class="col-sm-2 col-form-label">Pass type</label>
        <div class="col-sm-4">
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="pt-1"
          >
            <input
              type="radio"
              id="pt-1"
              class="mdl-radio__button"
              name="pt-options"
              [(ngModel)]="event.pass_type"
              value="pass"
            />
            <span class="mdl-radio__label">Pass</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="pt-2"
          >
            <input
              type="radio"
              id="pt-2"
              class="mdl-radio__button"
              name="pt-options"
              [(ngModel)]="event.pass_type"
              value="chip"
            />
            <span class="mdl-radio__label">Chip</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="pt-3"
          >
            <input
              type="radio"
              id="pt-3"
              class="mdl-radio__button"
              name="pt-options"
              [(ngModel)]="event.pass_type"
              value="rim"
            />
            <span class="mdl-radio__label">Rim</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="pt-4"
          >
            <input
              type="radio"
              id="pt-4"
              class="mdl-radio__button"
              name="pt-options"
              [(ngModel)]="event.pass_type"
              value="shot_pass"
            />
            <span class="mdl-radio__label">Shot Pass</span>
          </label>
        </div>

        <ng-container
          *ngIf="event.pass_type === 'pass' || event.pass_type === 'shot_pass'"
        >
          <label class="col-sm-2 col-form-label">Pass Receiver</label>
          <div class="col-sm-4">
            <select [(ngModel)]="event.pass_receiver" class="form-control">
              <option
                *ngFor="let p of players"
                selected="event.pass_receiver == p"
                >{{ p }}</option
              >
            </select>
          </div>

          <label class="col-sm-2 col-form-label">Pass outcome</label>
          <div class="col-sm-4">
            <label
              class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
              for="po-1"
            >
              <input
                type="radio"
                id="po-1"
                class="mdl-radio__button"
                name="po-options"
                [(ngModel)]="event.pass_outcome"
                value="complete"
              />
              <span class="mdl-radio__label">Complete</span>
            </label>
            <label
              class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
              for="po-2"
            >
              <input
                type="radio"
                id="po-2"
                class="mdl-radio__button"
                name="po-options"
                [(ngModel)]="event.pass_outcome"
                value="incomplete"
              />
              <span class="mdl-radio__label">Incomplete</span>
            </label>
            <label
              class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
              for="po-3"
            >
              <input
                type="radio"
                id="po-3"
                class="mdl-radio__button"
                name="po-options"
                [(ngModel)]="event.pass_outcome"
                value="-"
              />
              <span class="mdl-radio__label">-</span>
            </label>
          </div>
        </ng-container>
      </div>

      <div
        class="form-group row ing-align-content-center"
        *ngIf="event.eventType === 'oddMenRush'"
      >
        <label class="col-sm-2">Odd Men Rush Detail</label>
        <div class="col-sm-4">
          <mat-form-field>
            <mat-select [(value)]="event.oddMenRushDetail">
              <mat-option *ngFor="let o of oddMenRushDetails" [value]="o">{{
                o
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row" *ngIf="event.eventType === 'highlight'">
        <label class="col-sm-2">Highlight Type</label>
        <div class="col-sm-10">
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-1"
          >
            <input
              type="radio"
              id="ht-1"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="bench"
            />
            <span class="mdl-radio__label">Bench</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-2"
          >
            <input
              type="radio"
              id="ht-2"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="blooper"
            />
            <span class="mdl-radio__label">Blooper</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-3"
          >
            <input
              type="radio"
              id="ht-3"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="close_up"
            />
            <span class="mdl-radio__label">Close Up</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-4"
          >
            <input
              type="radio"
              id="ht-4"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="compilation"
            />
            <span class="mdl-radio__label">Compilation</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-5"
          >
            <input
              type="radio"
              id="ht-5"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="goal"
            />
            <span class="mdl-radio__label">Goal</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-6"
          >
            <input
              type="radio"
              id="ht-6"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="highlight_play"
            />
            <span class="mdl-radio__label">Highlight Play</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-7"
          >
            <input
              type="radio"
              id="ht-7"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="hit"
            />
            <span class="mdl-radio__label">Hit</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-8"
          >
            <input
              type="radio"
              id="ht-8"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="penalty_box"
            />
            <span class="mdl-radio__label">Penalty Box</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-9"
          >
            <input
              type="radio"
              id="ht-9"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="save"
            />
            <span class="mdl-radio__label">Save</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-10"
          >
            <input
              type="radio"
              id="ht-10"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="shot"
            />
            <span class="mdl-radio__label">Shot</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-11"
          >
            <input
              type="radio"
              id="ht-11"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="stands"
            />
            <span class="mdl-radio__label">Stands</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-12"
          >
            <input
              type="radio"
              id="ht-12"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="titles"
            />
            <span class="mdl-radio__label">Titles</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="ht-13"
          >
            <input
              type="radio"
              id="ht-13"
              class="mdl-radio__button"
              name="ht-options"
              [(ngModel)]="event.highlightType"
              (ngModelChange)="resetHighlightData()"
              value="penalty_shot"
            />
            <span class="mdl-radio__label">Penalty Shot</span>
          </label>
        </div>
        <label
          class="col-sm-2 col-form-label"
          *ngIf="
            event.highlightType &&
            !(
              event.highlightType === 'goal' ||
              event.highlightType === 'save' ||
              event.highlightType === 'penalty_shot'
            )
          "
          >Playback</label
        >
        <div
          class="col-sm-10"
          *ngIf="
            event.highlightType &&
            !(
              event.highlightType === 'goal' ||
              event.highlightType === 'save' ||
              event.highlightType === 'penalty_shot'
            )
          "
        >
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="htp-1"
          >
            <input
              type="radio"
              id="htp-1"
              class="mdl-radio__button"
              name="htp-options"
              [(ngModel)]="event.highlightPlayback"
              value="normal"
            />
            <span class="mdl-radio__label">Normal</span>
          </label>
          <label
            class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
            for="htp-2"
          >
            <input
              type="radio"
              id="htp-2"
              class="mdl-radio__button"
              name="htp-options"
              [(ngModel)]="event.highlightPlayback"
              value="slow_motion"
            />
            <span class="mdl-radio__label">Slow Motion</span>
          </label>
        </div>
        <label
          *ngIf="event.highlightType && event.highlightType !== 'titles'"
          class="col-sm-2 col-form-label"
          >Rating</label
        >
        <div
          *ngIf="event.highlightType && event.highlightType !== 'titles'"
          class="col-sm-4"
        >
          <app-star-rating
            [starCount]="5"
            [color]="'primary'"
            [ngModel]="event.highlightRating"
            (ngModelChange)="ratingChange($event)"
          ></app-star-rating>
        </div>
      </div>

      <div
        class="form-group row ing-align-content-center"
        *ngIf="event.eventType === 'game_incident' && isGameIncidentAllowed()"
      >
        <label class="col-sm-2">Game Incident Reason</label>
        <div class="col-sm-4">
          <mat-form-field>
            <mat-select
              [(value)]="event.gameIncidentReason"
              (selectionChange)="onGameIncidentChange()"
            >
              <mat-option
                *ngFor="let reason of allGameIncidentReasons"
                [value]="reason.key"
              >
                {{ reason.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <ng-template [ngIf]="isShowGameIncidentAdditionalAttributes()">
          <label class="col-sm-2">Player Injury</label>
          <div class="col-sm-4">
            <mat-checkbox
              [(ngModel)]="event.isGameIncidentWithPlayerInjury"
            ></mat-checkbox>
          </div>
          <label class="col-sm-2">Official's Action</label>
          <div class="col-sm-4">
            <mat-form-field>
              <mat-select
                [(value)]="event.officialsCallAction"
                (selectionChange)="onGameIncidentChange()"
              >
                <mat-option
                  *ngFor="let action of allOfficialsCallActions"
                  [value]="action.key"
                >
                  {{ action.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <label class="col-sm-2">Official's Assessment</label>
          <div class="col-sm-4">
            <mat-form-field>
              <mat-select
                [(value)]="event.officialsCallAssessment"
                (selectionChange)="onGameIncidentChange()"
              >
                <mat-option
                  *ngFor="let assessment of allOfficialsCallAssessments"
                  [value]="assessment.key"
                >
                  {{ assessment.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <ng-template [ngIf]="isShowGameIncidentCalledPenaltySeverity()">
            <label class="col-sm-2">Penalty Call Severity</label>
            <div class="col-sm-4">
              <mat-form-field>
                <mat-select
                  [(value)]="event.officialsPenaltyCallSeverity"
                  (selectionChange)="onGameIncidentChange()"
                >
                  <mat-option
                    *ngFor="let severity of allOfficialsPenaltyCallSeverities"
                    [value]="severity.key"
                  >
                    {{ severity.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-template>
          <ng-template [ngIf]="isShowGameIncidentUncalledPenaltySeverity()">
            <label class="col-sm-2">Uncalled Penalty Severity</label>
            <div class="col-sm-4">
              <mat-form-field>
                <mat-select [(value)]="event.officialsUncalledPenaltySeverity">
                  <mat-option
                    *ngFor="
                      let severity of allOfficialsUncalledPenaltySeverities
                    "
                    [value]="severity.key"
                  >
                    {{ severity.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-template>
        </ng-template>
      </div>

      <button
        mat-raised-button
        color="primary"
        class="save-button"
        (click)="saveManually()"
      >
        <ng-container *ngIf="!isEditMode">Save Event</ng-container>
        <ng-container *ngIf="isEditMode">Update Event</ng-container>
      </button>

      <button mat-button class="reset-button ml-3" (click)="resetManually()">
        Reset
      </button>
    </div>

    <div
      *ngIf="isIceRinkVisible()"
      class="col-xs-12 col-sm-4"
      style="text-align: right"
    >
      <div class="ice-rink-container" id="right">
        <app-ice-rink [game]="game"></app-ice-rink>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12">
      <mat-tab-group
        mat-stretch-tabs
        style="width: 100%"
        backgroundColor="primary"
      >
        <mat-tab label="Events">
          <app-events
            style="padding-top: 25px"
            [game]="game"
            [selectedEventId]="event._id"
            (edit)="editEvent($event)"
            (seek)="seekEvent($event)"
            (update)="updateEvent($event)"
          >
          </app-events>
        </mat-tab>
        <mat-tab label="Shifts">
          <div class="tab-head">
            <div class="tab-action-buttons">
              <!--<button mat-flat-button color="secondary" class="timeline" (click)="showTimeline()">
                <mat-icon>timeline</mat-icon> Timeline
              </button>-->

              <button
                mat-flat-button
                color="secondary"
                class="summary"
                (click)="showSummary()"
              >
                <mat-icon>assessment</mat-icon> Player Summary
              </button>
            </div>

            <app-player-filter
              [game]="game"
              [players]="filterablePlayers"
              (playerChange)="filteredPlayerId = $event"
            >
            </app-player-filter>
          </div>

          <app-shifts-overview
            [game]="game"
            [shifts]="shifts"
            [playerId]="filteredPlayerId"
            (seek)="seekEvent($event)"
          ></app-shifts-overview>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<video #videoElement preload="metadata" [hidden]="true"></video>
<app-video-player-preload
  *ngIf="videoTrim"
  [game]="game"
  [video]="videoTrim"
  (duration)="openVideoPlayerTrimDialog($event)"
  (error)="errorVideoPlayerTrimDialog($event)"
></app-video-player-preload>
