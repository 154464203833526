<mat-toolbar color="primary" class="mat-elevation-z6" *ngIf="showToolbar">
  <nav class="navigation">
    <a
      mat-button
      routerLink="/leagues"
      routerLinkActive="active-link"
      *ngIf="
        authService.hasAnyRole([
          'supervisor',
          'chief_data_officer',
          'process_manager',
          'team_manager',
          'quality_manager'
        ])
      "
      >Leagues</a
    >
    <a
      mat-button
      routerLink="/games"
      routerLinkActive="active-link"
      *ngIf="
        authService.hasAnyRole([
          'supervisor',
          'chief_data_officer',
          'process_manager',
          'team_manager',
          'quality_manager',
          'game_operator',
          'collector'
        ])
      "
      >Games</a
    >
    <a
      mat-button
      routerLink="/reviews"
      routerLinkActive="active-link"
      *ngIf="
        authService.hasAnyRole([
          'supervisor',
          'chief_data_officer',
          'process_manager',
          'team_manager',
          'quality_manager'
        ])
      "
      >Reviews</a
    >
  </nav>

  <span class="filler"></span>

  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <mat-icon>people</mat-icon>
      <span
        [matTooltip]="
          'Roles: ' + authService.currentRolesFormatted?.join(', ') | titlecase
        "
        >{{ authService.currentRolesFormatted?.join(', ') | titlecase }}</span
      >
    </button>
    <button mat-menu-item (click)="authService.logout()"
      ><mat-icon>power_settings_new</mat-icon> Sign Out</button
    >
  </mat-menu>

  <button
    mat-button
    title="Profile Menu"
    [matMenuTriggerFor]="menu"
    *ngIf="authService.isAuthenticated() | async"
  >
    <img [src]="authService.userProfile?.picture" *ngIf="false" />
    {{ authService.userProfile?.name }}
  </button>
</mat-toolbar>

<router-outlet></router-outlet>

<div class="app-version" *ngIf="showToolbar"
  >app version: {{ clientVersion }}</div
>
