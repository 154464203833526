import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as AwsS3Multipart from '@uppy/aws-s3-multipart';
import { Uppy, UppyFile } from '@uppy/core';
import * as DragDrop from '@uppy/drag-drop';
import * as StatusBar from '@uppy/status-bar';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { CameraAngle, Game, VideoVariantEnum } from '../domain/game';
import { League } from '../domain/league';
import { AlertService } from './alert.service';

export interface Configuration {
  videoUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class UploadVideoService {
  private uppy: Uppy;
  private isInitialized = false;

  private apiUrl = environment.API_HOST + '/api/s3';

  private league: League;
  private game: Game;
  cameraAngle: CameraAngle;
  variant: VideoVariantEnum;

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private http: HttpClient
  ) {}

  getConfiguration() {
    return firstValueFrom(
      this.http.get<Configuration>(`${this.apiUrl}/configuration`)
    );
  }

  public getUppyState() {
    return this.uppy.getState();
  }

  public init(game: Game, league: League) {
    this.league = league;
    this.game = game;
    this.initUppy();
    this.attachListeners();
    this.isInitialized = true;
  }

  private initUppy() {
    if (this.isInitialized) {
      return;
    }
    this.uppy = new Uppy({
      debug: !environment.production,
      autoProceed: true, // start upload immediately after adding files
      restrictions: {
        maxFileSize: 10737418240, // 10GB
        maxNumberOfFiles: 1,
        minNumberOfFiles: 0,
        allowedFileTypes: ['video/*']
      }
    })
      .use(AwsS3Multipart, {
        companionUrl: environment.API_HOST + '/api',
        companionHeaders: {
          Authorization: `Bearer ${this.authService.accessToken}`,
          'x-file-type': 'video'
        },
        limit: 6
      })
      .use(DragDrop, {
        target: `.drag-and-drop`
      })
      .use(StatusBar, {
        target: '.status-bar',
        showProgressDetails: true,
        hideAfterFinish: false
      });
    this.uppy.setOptions({ onBeforeFileAdded: this.fileAdded.bind(this) });
    this.isInitialized = true;
  }

  private attachListeners() {
    this.uppy.on('pause-all', () => {
      this.alertService.showInfo(`Upload paused`);
    });
    this.uppy.on('upload-retry', (fileID) => {
      this.alertService.showInfo(`Upload retried: ${fileID}`);
    });
    this.uppy.on('upload-error', (file, error, response) => {
      this.alertService.showError(error);
    });
    this.uppy.on('info-visible', () => {
      const info = this.getUppyState().info;
      if (info.type === 'error') {
        this.alertService.showError(`${info.message} ${info.details}`);
      } else {
        const message = info.details
          ? `${info.message} ${info.details}`
          : info.message;
        this.alertService.showInfo(message);
      }
    });
  }

  public cancelAll() {
    this.uppy.cancelAll();
  }

  public close() {
    this.uppy.close();
    this.isInitialized = false;
  }

  public async upload() {
    await this.uppy.upload();
  }

  private fileAdded(file: UppyFile) {
    const originalName = file.name;
    const currentFile = {
      ...file,
      name: this.determineVideoFileName()
    };
    this.uppy.setMeta({ originalName });
    return currentFile;
  }

  private determineVideoFileName() {
    const gameDate = this.game.date.split('T')[0];
    return `${this.league.shortName}/${this.game.season}/${gameDate}-${this.game.homeTeam}-${this.game.awayTeam}-${this.cameraAngle}-${this.variant}.mp4`;
  }

  on<T>(complete: string, fn: (T) => void) {
    this.uppy.on(complete, fn);
  }
}
