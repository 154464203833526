import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { Observable, from, throwError } from 'rxjs';
import {
  catchError,
  delay,
  map,
  mergeMap,
  retryWhen,
  share,
  tap,
  toArray
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { EventType, GameEvent, InterruptionType } from '../domain/game-event';
import { AlertService } from './alert.service';

const ALLOWED_DRAFT_EVENTS = ['face_off', 'penalty', 'shot', 'interruption'];

@Injectable()
export class EventService {
  private url = environment.API_HOST + '/api/events';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private alertService: AlertService
  ) {}

  getEvents(
    gameId: string,
    filters: Record<string, any>,
    skipValidation: boolean,
    page?: number,
    pageSize?: number
  ): Observable<[number, GameEvent[]]> {
    let params = new HttpParams({ fromObject: filters }).set(
      'skipValidation',
      skipValidation
    );
    if (!filters.draft) {
      params = params.delete('draft');
    }
    if (page && pageSize) {
      params = params.set('offset', page * pageSize);
    }
    if (pageSize) {
      params = params.set('limit', pageSize);
    }
    return this.http.get(`${this.url}/${gameId}`, { params }).pipe(
      map((json) => [json['count'], json['data']] as [number, GameEvent[]]),
      catchError(this.handleError)
    );
  }

  /**
   * Stream game actions as they are created/updated/deleted
   */
  getEventsAsStream(gameId: string): Observable<GameEvent> {
    return new Observable<GameEvent>((subscriber) => {
      const eventSource = new EventSourcePolyfill(
        `${this.url}/${gameId}/stream`,
        {
          headers: {
            Authorization: `Bearer ${this.authService.accessToken}`
          },
          heartbeatTimeout: 300000 // 5 minutes else 45 seconds by default, it initiates another request
        }
      );

      subscriber.add(() => {
        console.log('Disconnecting event source...');
        eventSource.close();
      });

      eventSource.addEventListener('gameAction', (m: any) => {
        const gameAction = JSON.parse(m.data);
        subscriber.next(gameAction);

        // returns unsubscribe function
        return () => eventSource.close();
      });
      eventSource.onopen = () => {
        this.alertService.showInfo('Live updates connected');
        console.log('Event source connected');
      };
      eventSource.onerror = (e) => {
        console.log('Event source error', e);
        subscriber.error(e);
      };
      eventSource.onclose = () => {
        console.log('Event source disconnected');
        subscriber.complete();
      };
    }).pipe(
      share(),
      retryWhen((errors) =>
        errors.pipe(
          tap(() => this.alertService.showError('Live updates disconnected')),
          // reconnect in 5 seconds
          delay(5 * 1000)
        )
      )
    );
  }

  getEvent(gameId: string, eventId: string): Observable<GameEvent> {
    const url = `${this.url}/${gameId}/${eventId}`;
    return this.http.get(url).pipe(map((json) => json as GameEvent));
  }

  private handleError(httpErrorResponse: HttpErrorResponse) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (httpErrorResponse.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errMsg = httpErrorResponse.message
        ? httpErrorResponse.message
        : httpErrorResponse.toString();
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (httpErrorResponse.status === 422) {
        // validation failed
        return throwError(new Error(httpErrorResponse.error.message));
      } else {
        errMsg = `${httpErrorResponse.status} - ${
          httpErrorResponse.statusText || ''
        } ${httpErrorResponse.error}`;
        console.error(
          `Backend returned code ${httpErrorResponse.status}, ` +
            `body was: ${httpErrorResponse.error}`
        );
      }
    }
    return throwError('An unexpected error occurred. Please try again later.');
  }

  saveAll(events: GameEvent[]): Observable<GameEvent[]> {
    return from(events).pipe(
      mergeMap((e) => this.save(e)),
      toArray()
    );
  }

  save(event: GameEvent): Observable<GameEvent> {
    return this.http
      .post<GameEvent>(this.url, event)
      .pipe(catchError(this.handleError));
  }

  delete(gameId, eventId) {
    return this.http
      .delete(`${this.url}/${gameId}/${eventId}`)
      .pipe(catchError(this.handleError));
  }

  updateStrengthState(gameId): Observable<any> {
    return this.http.post(`${this.url}/update-strength-state`, { gameId });
  }

  applyVideoTimeOffset(gameId, period, offset): Observable<any> {
    return this.http.post(`${this.url}/apply-video-time-offset`, {
      gameId,
      period,
      offset
    });
  }

  updateGameTime(gameId): Observable<any> {
    return this.http.post(`${this.url}/update-game-time`, { gameId });
  }

  updateVideoTime(gameId): Observable<any> {
    return this.http.post(`${this.url}/update-video-time`, { gameId });
  }

  updateSIHFPeriodEvents(gameId): Observable<any> {
    return this.http.post(`${this.url}/update-sihf-period-events`, {
      gameId
    });
  }

  recalculateFOINTGameTime(gameId: string, period: string): Observable<any> {
    return this.http.post(`${this.url}/update-game-time-foint`, {
      gameId,
      period
    });
  }

  splitShiftsByStrengthState(gameId: string): Observable<any> {
    return this.http.post(`${this.url}/split-shifts`, {
      gameId
    });
  }

  interpolateGameTime(gameId: string, videoTime: number): Observable<number> {
    return this.http
      .get<{ gameTime: number }>(
        `${this.url}/${gameId}/interpolate-game-time?videoTime=${videoTime}`
      )
      .pipe(map((data) => data.gameTime));
  }

  shouldSaveDraftEvent(
    isEditMode: boolean,
    isLiveDraftEvents: boolean,
    event: GameEvent
  ) {
    if (isEditMode || !isLiveDraftEvents) {
      return false;
    }

    if (
      this.isImmediateInterruption(event.eventType, event.interruption_type)
    ) {
      return true;
    }

    if (!this.isAllowedDraftEvent(event.eventType)) {
      return false;
    }

    return !this.areAdditionalStepsRequiredForDraftEvent(event);
  }

  isAllowedDraftEvent(eventType: string) {
    return ALLOWED_DRAFT_EVENTS.includes(eventType);
  }

  isImmediateInterruption(
    eventType: EventType,
    eventInterruptionType: InterruptionType
  ) {
    return eventType === 'interruption' && eventInterruptionType === undefined;
  }

  areRequiredFieldsForDraftShotEventPopulated(
    eventType: string,
    team: string,
    shotOutcome: string
  ) {
    return !!(team && shotOutcome && eventType === 'shot');
  }

  areRequiredFieldsForDraftPenaltyEventPopulated(
    eventType: string,
    team: string,
    penaltyType: string
  ) {
    return !!(team && penaltyType && eventType === 'penalty');
  }

  areAdditionalStepsRequiredForDraftEvent(event: GameEvent) {
    if (event.eventType === 'shot') {
      return !this.areRequiredFieldsForDraftShotEventPopulated(
        event.eventType,
        event.team,
        event.shotOutcome
      );
    } else if (event.eventType === 'penalty') {
      return !this.areRequiredFieldsForDraftPenaltyEventPopulated(
        event.eventType,
        event.team,
        event.penaltyType
      );
    } else {
      return false;
    }
  }

  exportGoalClips(gameId: string, goalIds: string[] = []) {
    return this.http.post<{ ok: boolean; message?: string }>(
      `${this.url}/export-goal-clips`,
      { gameId, goalIds }
    );
  }

  deduceAssists(
    goalEvent: GameEvent
  ): Observable<
    Pick<GameEvent, 'assist1' | 'assist1Id' | 'assist2' | 'assist2Id'>
  > {
    // Relevant events for assists:
    // All events of types 'puckPossession', 'face_off', 'pass', 'videoTag'
    // between last sequence breaker event and goal event
    function filterAssistRelevantEvents(gameEvents: GameEvent[]): GameEvent[] {
      const lastSequenceBreakerEventIndex = gameEvents.findIndex(
        (event) =>
          (event.eventType === 'puckPossession' && event.team !== 'neutral') ||
          event.eventType === 'face_off'
      );
      return gameEvents
        .slice(
          0,
          lastSequenceBreakerEventIndex !== -1
            ? lastSequenceBreakerEventIndex
            : gameEvents.length
        )
        .filter(
          (event) =>
            ['puckPossession', 'face_off', 'pass', 'videoTag'].includes(
              event.eventType
            ) && event?.team !== 'neutral'
        );
    }

    return this.getEvents(
      goalEvent.gameId,
      {
        period: goalEvent.period,
        videoTimeTo: goalEvent.videoTime,
        sortOrder: 'DESC'
      },
      false
    ).pipe(
      map(([_number, gameEvents]) => filterAssistRelevantEvents(gameEvents)),
      map((assistRelevantEvents) => {
        const primaryAssist = assistRelevantEvents.find(
          (event) =>
            event.team === goalEvent.team &&
            event.playerNumber !== goalEvent.playerNumber
        );
        const secondaryAssist = assistRelevantEvents.find(
          (event) =>
            event.team === goalEvent.team &&
            ![goalEvent.playerNumber, primaryAssist?.playerNumber]
              .filter((e) => e)
              .includes(event.playerNumber)
        );
        return {
          assist1: primaryAssist?.playerNumber,
          assist1Id: primaryAssist?.playerId,
          assist2: secondaryAssist?.playerNumber,
          assist2Id: secondaryAssist?.playerId
        };
      })
    );
  }
}
